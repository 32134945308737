import { FaLinkedin, FaFacebook, FaInstagram } from "react-icons/fa";
import { FiMapPin, FiMail } from "react-icons/fi";
import { BsPhone } from "react-icons/bs";

export default function Footer() {
  return (
    <section>
      <div className="w-full bg-indigo-900 text-white">
        <div className="xl:px-20 pb-12 lg:px-10 md:px-10 sm:px-5 px-5">
          <div className="w-full pt-12 flex flex-col sm:flex-row justify-start">
            <div className="w-full sm:w-2/5 pr-6 flex flex-col space-y-4 mt-3 items-center align-middle">
              <img src="/img/logo-2-branco.png" width={200} alt="Logo Tecnvs" />
              <p className="opacity-60">Transformando Ideias. E o seu ERP.</p>
            </div>
            <div className="w-full sm:w-1/5 flex flex-col space-y-4 mr-10">
              <p className={`font-semibold`}>Consultoria ERP</p>
              <a className="opacity-60">Implantação</a>
              <a className="opacity-60">Fábrica de Software</a>
              <a className="opacity-60">Mapeamento de processos</a>
              <a className="opacity-60">Gestão de Projetos</a>
            </div>
            <div className="w-full sm:w-1/5 flex flex-col space-y-4 mr-10">
              <p className={`font-semibold`}>Outras soluções</p>
              <a className="opacity-60">Aplicativos</a>
              <a className="opacity-60">Desenvolvimento Web</a>
              <a className="opacity-60">Data Science</a>
            </div>
            <div className="w-full sm:w-2/5 flex flex-col space-y-4">
              <p className={`font-semibold`}>Fale conosco</p>
              <p className="opacity-60 flex flex-row items-center">
                <i className="m-2">
                  <FiMapPin />
                </i>
                Rua Santa Izabel, 451 Vl Augusta - Guarulhos - SP
              </p>
              <p className="opacity-60 flex flex-row items-center">
                <i className="m-2">
                  <BsPhone />
                </i>
                55 + 11 9822-8100
              </p>
              <p className="opacity-60 flex flex-row items-center">
                <i className="m-2">
                  <FiMail />
                </i>
                contato@tecnvs.com.br
              </p>
              <div className="flex flex-row space-x-4">
                <i className="">
                  <FaLinkedin />
                </i>
                <i className="">
                  <FaFacebook />
                </i>
                <i className="">
                  <FaInstagram />
                </i>
              </div>
            </div>
          </div>
          <div className="opacity-60 pt-2 text-sm mt-8">
            <p>
              © 2013 - 2022 Tecnvs Consultoria. Todos os direitos reservados.
            </p>
            <p>
              Tecnvs é uma consultoria independente que não possui qualquer
              relação comercial, vínculo ou parceria com a TOTVS S/A.
            </p>
            <p>
              TOTVS, Microsiga e Protheus são marcas registradas pertecentes a
              TOTVS S/A.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
