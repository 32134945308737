import { FaSearch } from "react-icons/fa";
import { GiEyeTarget } from "react-icons/gi";
import { BsGraphUp, BsFillCalendarCheckFill } from "react-icons/bs";
import { MdOutlineAttachMoney } from "react-icons/md";

export default function About() {
  return (
    <>
      <section className={`bg-gray-200 p-5`}>
        <div
          className={`grid gap-20 mb-8 md:grid-cols-2 sm:grid-cols-1 mx-2.5 px-10`}
        >
          <div className={`ml-5 mt-5`}>
            <h2 className={` text-2xl font-bold text-gray-700`}>
              Melhore a gestão dos projetos
            </h2>
            <span className={`text-xl font-semibold text-gray-700 `}>
              da sua empresa
            </span>
            <p className={`pt-5 text-gray-500`}>
              Promovemos a adoção das melhores práticas, através da metodologia,
              para obtenção dos benefícios de negócio de maneira
              <span className={`text-blue-500 font-semibold`}>
                {" "}
                ágil e assertiva.
              </span>
            </p>

            <div className={`flex mt-5 items-center`}>
              <span
                className={`text-2xl text-red-400  mr-3 bg-red-200 rounded-md p-2`}
              >
                <FaSearch />
              </span>
              <h5 className={`font-semibold`}>Padronização dos processos</h5>
            </div>
            <p className={`text-sm text-gray-500`}>
              Gere mais resultados e com alta qualidade, gastando menos tempo e
              dinheiro, através da padronização e otimização dos seus processos
            </p>
            <div className={`flex mt-5 items-center`}>
              <span
                className={`text-2xl text-blue-400  mr-3 bg-blue-200 rounded-md p-2`}
              >
                <GiEyeTarget />
              </span>
              <h5 className={`font-semibold`}>Priorização e Foco</h5>
            </div>
            <p className={`text-sm text-gray-500`}>
              Auxiliamos a criar critétios para a priorização dos projetos
              conforme objetivos estratégicos da sua empresa
            </p>

            <div className={`flex mt-5 items-center`}>
              <span
                className={`text-2xl text-purple-400  mr-3 bg-purple-200 rounded-md p-2`}
              >
                <BsGraphUp />
              </span>
              <h5 className={`font-semibold`}>Visibilidade</h5>
            </div>
            <p className={`text-sm text-gray-500`}>
              Tenha uma visão centralizada de todos os projetos da sua empresa
            </p>
            <div className={`flex mt-5 items-center`}>
              <span
                className={`text-2xl text-blue-400  mr-3 bg-blue-200 rounded-md p-2`}
              >
                <BsFillCalendarCheckFill />
              </span>
              <h5 className={`font-semibold`}>Prazos em dia</h5>
            </div>
            <p className={`text-sm text-gray-500`}>
              Garanta cumprimentos de prazos e das entregas dos projetos
            </p>
            <div className={`flex mt-5 items-center`}>
              <span
                className={`text-2xl text-red-400  mr-3 bg-red-200 rounded-md p-2`}
              >
                <MdOutlineAttachMoney />
              </span>
              <h5 className={`font-semibold`}>Controle de custos</h5>
            </div>
            <p className={`text-sm text-gray-500`}>
              Controle eficiente dos custos dos seus projetos
            </p>
          </div>
          <div className={`ml-9 items-end align-middle`}>
            <img
              alt="About"
              width={600}
              height={600}
              src="/img/about-img.png"
            />
          </div>
        </div>
      </section>
    </>
  );
}
