import { IoLocationSharp } from "react-icons/io5";
import { FiMail, FiPhone } from "react-icons/fi";

export default function WhoWeAre() {
  return (
    <>
      <section className="pb-20 relative block bg-gray-100">
        <div className="container mx-auto px-4 lg:pt-7 lg:pb-7">
          <div className=" flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-8/12 px-4">
              <span
                className={`text-white text-sm 
                            bg-blue-500 rounded-full py-1 px-6`}
              >
                Contato
              </span>
              <h2 className="text-4xl font-semibold text-black mt-3">
                Como podemos ajudar você?
              </h2>
              <h4 className="text-2xl text-black p-3">
                Entre em contato para saber mais sobre nossas soluções. E não
                esqueça: tudo pode ser personalizado às necessidades do seu
                negócio.
              </h4>
            </div>
          </div>
        </div>
        <div
          className={`grid gap-20 mb-8 md:grid-cols-2 sm:grid-cols-1 mx-2.5 px-10`}
        >
          <div className={`ml-5 mt-5`}>
            <h2 className={` text-2xl font-bold text-gray-700`}>
              Vamos iniciar o seu próximo projeto?
            </h2>
            <p className={`pt-5 text-gray-500`}>
              Precisa de um contato comercial ou de alguma informação? Entre em
              contato com a gente!
            </p>

            <div className={`flex mt-5 items-center`}>
              <span
                className={`text-2xl text-red-400  mr-3 bg-red-200 rounded-md p-2`}
              >
                <IoLocationSharp />
              </span>
              <h5 className={`font-semibold`}>
                Rua Santa Izabel, 451 Vila Augusta - Guarulhos -SP
              </h5>
            </div>

            <div className={`flex mt-5 items-center`}>
              <span
                className={`text-2xl text-blue-400  mr-3 bg-blue-200 rounded-md p-2`}
              >
                <FiMail />
              </span>
              <h5 className={`font-semibold`}>contato@tecnvs.com.br</h5>
            </div>

            <div className={`flex mt-5 items-center`}>
              <span
                className={`text-2xl text-purple-400  mr-3 bg-purple-200 rounded-md p-2`}
              >
                <FiPhone />
              </span>
              <h5 className={`font-semibold`}>+55 11 98226-8100</h5>
            </div>
          </div>
          <div className={`ml-5 mt-5 rounded-full`}>
            <img
              className="rounded-full"
              alt="About"
              width={500}
              height={500}
              src="/img/about-contact.jpg"
            />
          </div>
        </div>
      </section>
    </>
  );
}
