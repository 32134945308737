import { FaRegSmileWink, FaCloud, FaTools, FaPalette } from "react-icons/fa";
import { BsBezier } from "react-icons/bs";
import { MdSupportAgent } from "react-icons/md";

export default function MobilePromo() {
  return (
    <>
      <section id="Mobile" className="pb-5 relative block bg-gray-100">
        <div className="container mx-auto px-4 lg:pt-7 lg:pb-7">
          <div className=" flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-8/12 px-4">
              <span
                className={`text-white text-sm 
                            bg-blue-500 rounded-full py-1 px-6`}
              >
                Mobile
              </span>
              <h2 className="text-4xl font-semibold text-black mt-3">
                Ganhe mais mobilidade no seu dia a dia
              </h2>
              <h4 className="text-2xl text-black p-3">
                Aumente sua produtividade com nossos aplicativos
              </h4>
              <p className={`text-gray-700`}>
                Nossos apps são a escolha certa para o seu negócio. Com eles, a
                sua empresa unifica processos, pessoas e sistemas de uma forma
                fácil e intuitiva. Tenha a possibilidade de tomar decisões de
                qualquer lugar, acessando as principais informações da sua
                empresa apenas pelo celular.
              </p>
            </div>
          </div>
          <div className={`container mt-7`}>
            <div
              className={`sm:flex mb-4 items-center justify-between align-middle`}
            >
              <div className={`sm:h-1/3 flex flex-col m-5`}>
                <div className={`flex flex-row items-center p-5`}>
                  <i
                    className={`bg-purple-200 text-purple-500 p-3  rounded-full text-2xl mr-10`}
                  >
                    <FaRegSmileWink />
                  </i>
                  <div className={`flex-col text-gray-700`}>
                    <h5 className={`text-gray-900 font-semibold`}>
                      Experiência do usuário
                    </h5>
                    <p>Apps pensados para facilitar a vida do usuário</p>
                  </div>
                </div>
                <div className={`flex flex-row items-center p-5`}>
                  <i
                    className={`bg-blue-200 text-blue-500 p-3 rounded-full text-2xl mr-10`}
                  >
                    <BsBezier />
                  </i>
                  <div className={`flex-col text-gray-700 `}>
                    <h5 className={`text-gray-900 font-semibold`}>
                      Fácil de integrar
                    </h5>
                    <p className={`flex-col text-gray-700`}>
                      Fornecemos API´s para integração com outros Sistemas,
                      permitindo o acesso aos dados facilmente.
                    </p>
                  </div>
                </div>
                <div className={`flex flex-row items-center p-5`}>
                  <i
                    className={`bg-red-200 text-red-500 p-3 rounded-full text-2xl mr-10`}
                  >
                    <MdSupportAgent />
                  </i>
                  <div className={`flex-col text-gray-700 `}>
                    <h5 className={`text-gray-900 font-semibold`}>Suporte</h5>
                    <p className={`flex-col text-gray-700`}>
                      Conte conosco em caso de dúvidas na utilização do App.
                    </p>
                  </div>
                </div>
              </div>
              <div className={`sm:2/3 flex flex-col p-5`}>
                <img src="/img/image-14.png" alt="app" width={650} />
              </div>
              <div className={`sm:h-3/3 flex flex-col m-5`}>
                <div className={`flex flex-row items-center p-5`}>
                  <i
                    className={`bg-purple-200 text-purple-500 p-3  rounded-full text-2xl mr-10`}
                  >
                    <FaTools />
                  </i>
                  <div className={`flex-col text-gray-700`}>
                    <h5 className={`text-gray-900 font-semibold`}>
                      Atualização e manutenção de aplicativos
                    </h5>
                    <p>Apps pensados para facilitar a vida do usuário</p>
                  </div>
                </div>
                <div className={`flex flex-row items-center p-5`}>
                  <i
                    className={`bg-blue-200 text-blue-500 p-3 rounded-full text-2xl mr-10`}
                  >
                    <FaCloud />
                  </i>
                  <div className={`flex-col text-gray-700 `}>
                    <h5 className={`text-gray-900 font-semibold`}>
                      Modelo SaaS
                    </h5>
                    <p className={`flex-col text-gray-700`}>
                      Não se preocupe com infraestrutura. Cuidamos de tudo para
                      você.
                    </p>
                  </div>
                </div>
                <div className={`flex flex-row items-center p-5`}>
                  <i
                    className={`bg-red-200 text-red-500 p-3 rounded-full text-2xl mr-10`}
                  >
                    <FaPalette />
                  </i>
                  <div className={`flex-col text-gray-700 `}>
                    <h5 className={`text-gray-900 font-semibold`}>Design</h5>
                    <p className={`flex-col text-gray-700`}>
                      Criação de interfaces atraentes e a melhor experiências de
                      usuário para transformar seu negócio!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
