import { useEffect, useState } from "react";

export default function Header() {
  // Tratamento para esconder BG NavBar
  const [top, setTop] = useState(true);
  // Tratamento para esconder menu em telas pequenas
  const [menuMobile, setMenuMobile] = useState(false);
  const showMenuMobile = () => setMenuMobile(!menuMobile);

  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 20 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [top]);

  // Menu Consultoria
  const [menuErp, setMenuErp] = useState(false);
  const showMenuErp = () => setMenuErp(!menuErp);

  return (
    <>
      <nav
        id="header"
        className={`fixed w-full z-30 top-0 ${
          top
            ? "sm:bg-transparent md:bg-transparent  p-4 lg:p-0"
            : "sm:bg-indigo-900 md:bg-indigo-900  lg: bg-indigo-900 p-4 lg:p-0 bg-opacity-90"
        }`}
      >
        <div
          className={`w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2 z-10`}
        >
          <div className={`pl-4 mt-2 flex items-center`}>
            <a href="/">
              <img src="/img/logo-2-branco.png" alt="Logo Tecnvs" />
            </a>
          </div>
          <div className={`block lg:hidden pr-4`}>
            <button
              id="nav-toggle"
              className={`flex items-center p-3 mr-4 text-white hover:text-gray-100 
                focus:outline-none focus:shadow-outline transform transition
                hover:scale-105 duration-300 ease-in-out`}
              onClick={showMenuMobile}
            >
              <svg
                className="fill-current h-6 w-6"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          {menuMobile ? (
            <div
              className={`w-full  flex-grow sm:bg-indigo-900 md:bg-indigo-900  lg: bg-indigo-900 p-4 lg:p-0 bg-opacity-90"`}
            >
              <br />
              <ul
                className={`list-reset flex-col justify-end flex-1 items-center mt-2 ml-5`}
              >
                <li className={`mr-3`}>
                  <a
                    href="/erp"
                    className={`cursor-pointer inline-block text-white no-underline hover: hover:text-gray-200 font-bold py-2 px-2`}
                  >
                    Quem Somos
                  </a>
                </li>
                <li className={`mr-3`}>
                  <a
                    href="/erp"
                    className={`cursor-pointer inline-block text-white no-underline hover: hover:text-gray-200 font-bold py-2 px-2`}
                  >
                    Serviços
                  </a>
                  <ul
                    className={`list-reset flex-col justify-end flex-1 items-center mt-2 ml-5`}
                  >
                    <li>
                      <a
                        href="/"
                        className={`cursor-pointer inline-block text-white no-underline hover: hover:text-gray-200 font-bold py-2 px-2`}
                      >
                        Consultoria ERP
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className={`cursor-pointer inline-block text-white no-underline hover: hover:text-gray-200 font-bold py-2 px-2`}
                      >
                        Mapeamento de Processos
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className={`cursor-pointer inline-block text-white no-underline hover: hover:text-gray-200 font-bold py-2 px-2`}
                      >
                        Gestão de projetos
                      </a>
                    </li>
                  </ul>
                </li>
                <li className={`mr-3`}>
                  <a
                    href="/erp"
                    className={`cursor-pointer inline-block text-white no-underline hover: hover:text-gray-200 font-bold py-2 px-2`}
                  >
                    Aplicativos
                  </a>
                </li>
                <li className={`mr-3`}>
                  <a
                    href="/contato"
                    className={`cursor-pointer inline-block text-white no-underline hover: hover:text-gray-200 font-bold py-2 px-2`}
                  >
                    Contato
                  </a>
                </li>
                <li className={`mr-3`}>
                  <a
                    href="/embreve"
                    className={`cursor-pointer inline-block text-white no-underline hover: hover:text-gray-200 font-bold py-2 px-2`}
                  >
                    Login
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
          <div
            className={`w-full  flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 
              lg:mt-0 `}
          >
            <ul
              className={`list-reset lg:flex justify-end flex-1 items-center mt-3`}
            >
              <li className={`mr-3`}>
                <a
                  href="/erp"
                  className={`cursor-pointer inline-block text-white no-underline hover: hover:text-gray-200 font-bold py-2 px-4`}
                >
                  Quem Somos
                </a>
              </li>
              <li className={`mr-3`}>
                <button
                  onClick={showMenuErp}
                  className={`flex flex-row cursor-pointer text-white no-underline hover:text-gray-200 font-bold py-2 px-4`}
                >
                  Serviços
                  <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {menuErp ? (
                  <div
                    className={`absolute mt-1 top-full  h-auto min-w-max bg-purple-500 bg-opacity-80 rounded-md z-20`}
                  >
                    <ul className={`block text-center text-sm p-3 space-y-3 `}>
                      <li>
                        <a
                          href="/erp"
                          className={`no-underline cursor-pointer text-gray-200 hover:text-white p-5 m-3`}
                        >
                          Consultoria ERP
                        </a>
                      </li>
                      {/*
                      <li>
                        <a
                          href="/"
                          className={`no-underline cursor-pointer text-gray-200 hover:text-white p-5 m-3`}
                        >
                          Desenvolvimento Web
                        </a>
                      </li>
                      
                      <li>
                        <a
                          href="/"
                          className={`no-underline cursor-pointer text-gray-200 hover:text-white p-5 m-3`}
                        >
                          Mobile
                        </a>
                      </li>
                      */}
                      <li>
                        <a
                          href="/bpm"
                          className={`no-underline cursor-pointer text-gray-200 hover:text-white p-5 m-3`}
                        >
                          Mapeamento de Processos
                        </a>
                      </li>
                      <li>
                        <a
                          href="/Pmo"
                          className={`no-underline cursor-pointer text-gray-200 hover:text-white p-5 m-3`}
                        >
                          Gestão de Projetos
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </li>
              <li className={`mr-3`}>
                <a
                  href="/embreve"
                  className={`cursor-pointer inline-block text-white no-underline hover: hover:text-gray-200 font-bold py-2 px-4`}
                >
                  Aplicativos
                </a>
              </li>
              <li className={`mr-3`}>
                <a
                  href="/contato"
                  className={`cursor-pointer inline-block text-white no-underline hover: hover:text-gray-200 font-bold py-2 px-4`}
                >
                  Contato
                </a>
              </li>
              <li className={`ml-8 mr-3`}>
                <a
                  href="/embreve"
                  className={`cursor-pointer inline-block text-white no-underline hover: hover:text-gray-200 font-bold py-2 px-4`}
                >
                  Login
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
