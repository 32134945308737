import { FaSearch, FaRegHandshake } from "react-icons/fa";
import { AiOutlineDashboard } from "react-icons/ai";

export default function About() {
  return (
    <>
      <section className={`bg-gray-200 p-5`}>
        <div
          className={`grid gap-20 mb-8 md:grid-cols-2 sm:grid-cols-1 mx-2.5 px-10`}
        >
          <div className={`ml-5 mt-5`}>
            <h2 className={` text-2xl font-bold text-gray-700`}>
              Ajudamos a impulsionar seus negócios
            </h2>
            <span className={`text-xl font-semibold text-gray-700 `}>
              com muito mais agilidade
            </span>
            <p className={`pt-5 text-gray-500`}>
              Acreditamos que indivíduos e suas interações fazem o seu e o nosso
              negócio.
            </p>
            <p className={`text-gray-500`}>
              Este é o nosso jeito de cuidar do seu ERP:
              <span className={`text-blue-500 font-semibold`}>
                {" "}
                com tecnologia e simplicidade.
              </span>
            </p>
            <div className={`flex mt-5 items-center`}>
              <span
                className={`text-2xl text-red-400  mr-3 bg-red-200 rounded-md p-2`}
              >
                <FaSearch />
              </span>
              <h5 className={`font-semibold`}>Foco na solução</h5>
            </div>
            <p className={`text-sm text-gray-500`}>
              Nos preocupamos com aquele detalhe a mais que faz a diferença no
              seu projeto.
            </p>
            <div className={`flex mt-5 items-center`}>
              <span
                className={`text-2xl text-blue-400  mr-3 bg-blue-200 rounded-md p-2`}
              >
                <AiOutlineDashboard />
              </span>
              <h5 className={`font-semibold`}>Agilidade e Qualidade</h5>
            </div>
            <p className={`text-sm text-gray-500`}>
              Nós praticamos o que pregamos! Somos certificados nas melhores
              práticas de gerenciamento ágil. Aqui, alinhamos a metodologia ágil
              às necessidades do seu negócio para um resultado personalizado e
              eficaz.
            </p>
            <div className={`flex mt-5 items-center`}>
              <span
                className={`text-2xl text-red-400  mr-3 bg-red-200 rounded-md p-2`}
              >
                <FaRegHandshake />
              </span>
              <h5 className={`font-semibold`}>Atendimento Personalizado</h5>
            </div>
            <p className={`text-sm text-gray-500`}>
              Nós praticamos o que pregamos! Somos certificados nas melhores
              práti cas de gerenciamento ágil. Aqui, alinhamos a metodologia
              ágil às necessidades do seu negócio para um resultado
              personalizado e eficaz.
            </p>
          </div>
          <div className={`ml-5 mt-5`}>
            <img
              alt="About"
              width={500}
              height={500}
              src="/img/about-img.png"
            />
          </div>
        </div>
      </section>
    </>
  );
}

/*
      
        {/*
        <section id="AboutUs" className={`flex flex-row bg-gray-100 mt`}>
          <div className={`flex-row container grid-cols-2 gap-5 mt-5`}>
            <div className={` flex-col  text-gray-700 min-w-min`}>
              <h2 className={` text-3xl font-bold text-gray-700`}>
                Ajudamos a impulsionar seus negócios
              </h2>
              <span className={`text-2xl font-semibold text-gray-700 `}>
                com muito mais agilidade
              </span>
              <p className={`pt-5`}>
                Acreditamos que indivíduos e suas interações fazem o seu e o
                nosso negócio. Este é o nosso jeito de cuidar do seu ERP:
              </p>
              <p>Com tecnologia e simplicidade.</p>
              <div>
                <h5>Foco na solução</h5>
                <p>
                  Nos preocupamos com aquele detalhe a mais que faz a diferença
                  no seu projeto.
                </p>
              </div>
              <div>
                <h5>Agilidade e Qualidade</h5>
                <p>
                  Nós praticamos o que pregamos! Somos certificados nas melhores
                  práticas de gerenciamento ágil. Aqui, alinhamos a metodologia
                  ágil às necessidades do seu negócio para um resultado
                  personalizado e eficaz.
                </p>
              </div>
              <div>
                <h5>Atendimento Personalizado</h5>
                <p>
                  Temos as pessoas como foco. 
                  Entendemos o melhor para o seu negócio e oferecemos 
                  as soluções mais adequadas à sua empresa.  
                </p>
              </div>
              <div>
            </div>
            </div>
            <div className={`mr-10`}>
              <Image alt="About"
                width={500}
                height={500}                
                src="/img/about-img.png" />
            </div>
          </div>
        </section>
        */
