import React from "react";
import Home from "./pages/home";
import Main from "./routers/main";
import "./styles/styles.css";

function App() {
  return (
    <>
      <Main />
    </>
  );
}

export default App;
