import React from "react";
import Herosection from "../components/Herosection/Hero404";

export default function notfound() {
  return (
    <>
      <Herosection />
    </>
  );
}
