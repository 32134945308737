import { Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import Erp from "../pages/erp";
import Pmo from "../pages/pmo";
import Bpm from "../pages/bpm";
import Comingsoon from "../pages/comingsoon";
import NotFound from "../pages/404";
import Contact from "../pages/contato";

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/erp" element={<Erp />} />
      <Route path="/pmo" element={<Pmo />} />
      <Route path="/bpm" element={<Bpm />} />
      <Route path="/embreve" element={<Comingsoon />} />
      <Route path="/notfound" element={<NotFound />} />
      <Route path="/contato" element={<Contact />} />
    </Routes>
  );
};
export default Main;
