import { FaEye, FaUserAlt, FaChartBar, FaMedal } from "react-icons/fa";

export default function WhoWeAre() {
  return (
    <>
      <section className="pb-20 relative block bg-gray-100">
        <div className="container mx-auto px-4 lg:pt-7 lg:pb-7">
          <div className=" flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-8/12 px-4">
              <span
                className={`text-white text-sm 
                            bg-blue-500 rounded-full py-1 px-6`}
              >
                Quem Somos
              </span>
              <h2 className="text-4xl font-semibold text-black mt-3">
                Mais que uma consultoria:
              </h2>
              <h4 className="text-2xl text-black p-3">
                O parceiro certo para a sua empresa
              </h4>
              <p className={`text-gray-700`}>
                A Tecnvs (leia-se Tecnus) é consultoria independente e
                Especializada em Gestão ERP e tem como missão oferecer as
                melhores soluções para os processos da sua empresa, e dessa
                forma, ajudamos a obter os melhores resultados, com o melhor
                custo-benefício e o melhor uso do ERP Protheus.
              </p>
              <p className={`text-gray-800 font-semibold mt-2`}>
                Nossos valores:
              </p>
            </div>
          </div>
        </div>
        <div
          className={`grid gap-20 mb-2 md:grid-cols-4 sm:grid-cols-2 mx-2.5 px-10`}
        >
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-2xl`}>
              <FaUserAlt />
            </i>
            <h4 className="font-bold">Pessoas em 1º lugar</h4>
            <p className="font-normal">Somos pessoas servindo pessoas</p>
          </div>
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-4xl`}>
              <FaEye />
            </i>
            <h4 className="font-bold">Atitude de dono</h4>
            <p className="font-normal">Pensamos e agimos como donos</p>
          </div>
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-4xl`}>
              <FaChartBar />
            </i>
            <h4 className="font-bold">Crescimento</h4>
            <p className="font-normal">
              Crescemos e aprendemos juntos com nossos clientes.
            </p>
          </div>
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-4xl`}>
              <FaMedal />
            </i>
            <h4 className="font-bold">Excelência</h4>
            <p className="font-normal">
              Buscamos a excelência e a simplicidade.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
