import Header from "../components/Header";
import HeroSection from "../components/Herosection/HeroErp";
import About from "../components/About";
import Footer from "../components/Footer";
import WhoWeAre from "../components/WhoWeAre/WhoWeAreErp";

export default function Erp() {
  return (
    <>
      <Header />
      <HeroSection />
      <WhoWeAre />
      <About />
      <Footer />
    </>
  );
}
