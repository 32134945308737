export default function HeroPmo() {
  return (
    <>
      <section>
        <div className="h-96 min-w-full bg-cover bg-gradient-to-b from-indigo-900 to-purple-600">
          <div
            className="h-screen min-w-full opacity-50"
            style={{
              backgroundImage: "url(./img/hero-bg-5.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
      </section>
    </>
  );
}
