import Footer from "../components/Footer";
import Header from "../components/Header";
import Herosection from "../components/Herosection/HeroContact";
import WhoWeAre from "../components/WhoWeAre/WhoWeAreContact";
import About from "../components/About";

export default function contato() {
  return (
    <>
      <Header />
      <Herosection />
      <WhoWeAre />
      <About />
      <Footer />
    </>
  );
}
