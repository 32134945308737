import { FaEye, FaFireExtinguisher, FaRocket, FaUserTie } from "react-icons/fa";

export default function WhoWeAre() {
  return (
    <>
      <section className="pb-20 relative block bg-gray-100">
        <div className="container mx-auto px-4 lg:pt-7 lg:pb-7">
          <div className=" flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-8/12 px-4">
              <span
                className={`text-white text-sm 
                            bg-blue-500 rounded-full py-1 px-6`}
              >
                PMO
              </span>
              <h2 className="text-4xl font-semibold text-black mt-3">
                Gestão de Projetos
              </h2>
              <h4 className="text-2xl text-black p-3">
                Melhore a gestão de projetos da sua organização
              </h4>
              <p className={`text-gray-700`}>
                Uma boa gestão de projetos é essencial para quea a implantação
                ERP na empresa alcance seus objetivos estratégicos. Por isso,
                contratar uma consultoria em gestão de projetos pode ser uma
                opção interessante para dar um gás no gerenciamento de projetos
                e fazer mudanças para manter a competitividade de negócio.
              </p>
              <br />
              <p className={`text-gray-700`}>
                Conte com profissionais certificados por entidades
                internacionais, como PMI e Scrum Alliance.
              </p>
              <p className={`text-gray-800 font-semibold mt-2`}>Saiba como:</p>
            </div>
          </div>
        </div>
        <div
          className={`grid gap-20 mb-2 md:grid-cols-4 sm:grid-cols-2 mx-2.5 px-10`}
        >
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-4xl`}>
              <FaEye />
            </i>
            <h4 className="font-bold">Projetos Críticos</h4>
            <p className="font-normal">
              Contrate um gerente de projetos especializado e diminua os riscos
              de falhas
            </p>
          </div>
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-3xl`}>
              <FaRocket />
            </i>
            <h4 className="font-bold">Gestão Ágil</h4>
            <p className="font-normal">
              Ajudamos a sua empresa a priorizar o seu portfólio de projetos
            </p>
          </div>
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-3xl`}>
              <FaFireExtinguisher />
            </i>
            <h4 className="font-bold">Recuperação de projetos</h4>
            <p className="font-normal">
              Conte com a nossa experência para colocar o seu projeto nos
              trilhos novamente
            </p>
          </div>
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-3xl`}>
              <FaUserTie />
            </i>
            <h4 className="font-bold">PMO ERP</h4>
            <p className="font-normal">
              Conte com o nosso PMO para garantir os objetivos estratégicos da
              sua empresa.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
