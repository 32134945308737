import Header from "../components/Header";
import HeroSection from "../components/Herosection/HeroPmo";
import About from "../components/About/AboutPmo";
//import PromoSection from "../components/PromoSection/PromoSection";
import Footer from "../components/Footer";
import WhoWeAre from "../components/WhoWeAre/WhoWeArePmo";

export default function Pmo() {
  return (
    <>
      <Header />
      <HeroSection />
      <WhoWeAre />
      <About />
      <Footer />
    </>
  );
}
