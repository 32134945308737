import { FaSearch, FaLink } from "react-icons/fa";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import { VscSettingsGear } from "react-icons/vsc";

export default function WhoWeAre() {
  return (
    <>
      <section className="pb-20 relative block bg-gray-100">
        <div className="container mx-auto px-4 lg:pt-7 lg:pb-7">
          <div className=" flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-8/12 px-4">
              <span
                className={`text-white text-sm 
                            bg-blue-500 rounded-full py-1 px-6`}
              >
                ERP
              </span>
              <h2 className="text-4xl font-semibold text-black mt-3">
                Consultoria ERP
              </h2>
              <h4 className="text-2xl text-black p-3">
                Aproveite ao máximo todos os recursos e funcionalidades do seu
                ERP
              </h4>
              <p className={`text-gray-700`}>
                A Tecnvs (leia-se Tecnus) é uma consultoria independente e
                Especializada em Sistemas de Gestão ERP e tem como missão
                oferecer as melhores soluções para os processos da sua empresa e
                dessa forma, ajudar a obter os melhores resultados, com o melhor
                custo-benefício e o melhor uso do ERP Protheus.
              </p>
              <p className={`text-gray-800 font-semibold mt-2`}>
                Nossos serviços:
              </p>
            </div>
          </div>
        </div>
        <div
          className={`grid gap-20 mb-2 md:grid-cols-4 sm:grid-cols-2 mx-2.5 px-10`}
        >
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-4xl`}>
              <HiOutlineDesktopComputer />
            </i>
            <h4 className="font-bold">Implantação</h4>
            <p className="font-normal">
              Implantação com total aderência ao seu negócio, alta qualidade de
              entrega.
            </p>
          </div>
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-3xl`}>
              <VscSettingsGear />
            </i>
            <h4 className="font-bold">Customização</h4>
            <p className="font-normal">
              Personalize o seu sistema ERP com nossa fábrica de software ADVPL.
            </p>
          </div>
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-3xl`}>
              <FaSearch />
            </i>
            <h4 className="font-bold">Mapeamento</h4>
            <p className="font-normal">
              Mapeamento, modelagem e otimizacão de processos.
            </p>
          </div>
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-3xl`}>
              <FaLink />
            </i>
            <h4 className="font-bold">Integrações</h4>
            <p className="font-normal">
              Integre o seu ERP com outros softwares e as demais aplicações de
              sua empresa.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
