import React from "react";
import Header from "../components/Header";
import Herosection from "../components/Herosection";
import WhoWeAre from "../components/WhoWeAre";
import About from "../components/About";
import MobilePromo from "../components/MobilePromo";
import Footer from "../components/Footer";

const Home =() => {
  return (
    <>
      <Header />
      <Herosection />
      <WhoWeAre />
      <About />
      <MobilePromo />
      <Footer />
    </>
  );
}

export default Home;
