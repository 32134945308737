import { FaMedapps, FaChartBar, FaMap } from "react-icons/fa";
import { AiOutlineAudit } from "react-icons/ai";

export default function WhoWeAre() {
  return (
    <>
      <section className="pb-20 relative block bg-gray-100">
        <div className="container mx-auto px-4 lg:pt-7 lg:pb-7">
          <div className=" flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-8/12 px-4">
              <span
                className={`text-white text-sm 
                            bg-blue-500 rounded-full py-1 px-6`}
              >
                BPM
              </span>
              <h2 className="text-4xl font-semibold text-black mt-3">
                Consultoria em Gestão de Processos
              </h2>
              <h4 className="text-2xl text-black p-3">
                Eleve a performance da sua organização e do seu ERP
              </h4>
              <p className={`text-gray-700`}>
                Se a sua empresa enfrenta problemas de produtividade ou excesso
                de erros, pode ser que a raiz de tudo esteja nos processos
                mapeados durante a implantação do seu ERP. Nesse caso, trazer um
                profissional externo pode ser a saída para aumentar o desempenho
                da sua organização. A Tecnvs pode te ajudar a mapear e
                identificar pontos de melhoria para o seu Sistema ERP
                transformar os processos da sua empresa de forma ágil e
                assertiva.
              </p>
              <p className={`text-gray-800 font-semibold mt-2`}>Saiba como:</p>
            </div>
          </div>
        </div>
        <div
          className={`grid gap-20 mb-2 md:grid-cols-4 sm:grid-cols-2 mx-2.5 px-10`}
        >
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-4xl`}>
              <FaMap />
            </i>
            <h4 className="font-bold">Mapeamento</h4>
            <p className="font-normal">
              Elaboramos fluxos de processos, envolvendo os colaboradores na
              construção
            </p>
          </div>
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-3xl`}>
              <FaMedapps />
            </i>
            <h4 className="font-bold">Transformação</h4>
            <p className="font-normal">
              Aumente a sua produtividade e agregue mais valor aos seus clientes
              e ao seu ERP
            </p>
          </div>
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-3xl`}>
              <AiOutlineAudit />
            </i>
            <h4 className="font-bold">Auditoria</h4>
            <p className="font-normal">
              Identifiquei melhorias e riscos para o negócio.
            </p>
          </div>
          <div
            className={`
                        flex flex-col h-40 min-w-0 p-4 rounded-lg shadow-lg
                        text-gray-700 hover:text-white
                        bg-white text-center justify-items-start
                        hover:bg-purple-700 `}
          >
            <i className={`text-3xl`}>
              <FaChartBar />
            </i>
            <h4 className="font-bold">Escritório de Processos</h4>
            <p className="font-normal">
              Priorize, otimize e estabelaça indicadores de desempenho.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
