import { Link } from "react-router-dom";

export default function Herosection() {
  return (
    <>
      <div className="pt-50 bg-purple-700 text-white">
        <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center absolute z-10">
          <div className="flex flex-col w-full text-center pt-64">
            <h1 className=" text-5xl font-bold leading-tight text">
              Você cuida do seu negócio.
              <br /> E nós do seu ERP.
            </h1>
            <p className="py-4 text-center text-2xl lg:px-10">
              Confie em quem entende de ERP. Mais do que uma consultoria, somos
              seus parceiros.
            </p>
            <div className={`items-center`}>
              <Link to={"/contato"}>
                <button
                  className={`bg-white mt-8 w-max rounded-full 
              hover:bg-transparent text-purple-700
              font-bold hover:text-white
              py-2 px-4 border border-white
              hover:border-white`}
                >
                  Entre em Contato
                </button>
              </Link>
              <div className="row justify-between mt-16 mb-10 align-middle">
                <div className="inline-flex ml-10">
                  <p className="">Especializados em: </p>
                  <ul className="inline-flex gap-14 ">
                    <li className="ml-14 mr-5">
                      <img
                        className={`w-20`}
                        src="/img/logo-totvs.png"
                        alt="Logo Totvs"
                      />
                    </li>
                    <li className="mr-5">
                      <img
                        className={`w-20`}
                        src="/img/logo-node.png"
                        alt="Logo NodeJS"
                      />
                    </li>
                    <li className="mr-5">
                      <img
                        className={`w-10`}
                        src="/img/logo-react.png"
                        alt="Logo REACT"
                      />
                    </li>
                    <li className="mr-5">
                      <img className={`w-6`} src="/img/logo-flutter.png" />
                    </li>
                    <li className="mr-5">
                      <img className={`w-6`} src="/img/logo_powerBi.png" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`m-0 bottom-0 min-h-screen min-w-max h-auto overflow-hidden bg-cover z-10 bg-purple-700 opacity-40`}
        >
          <video
            className={`min-h-screen min-w-full`}
            id="video"
            src="/media/tecnvs-video-bg.mp4"
            autoPlay
            loop
            muted
          />
        </div>
      </div>
    </>
  );
}
