import Header from "../components/Header";
import HeroSection from "../components/Herosection/HeroBpm";
import About from "../components/About/AboutBpm";
import Footer from "../components/Footer";
import WhoWeAre from "../components/WhoWeAre/WhoWeAreBpm";

export default function bpm() {
  return (
    <>
      <Header />
      <HeroSection />
      <WhoWeAre />
      <About />
      <Footer />
    </>
  );
}
