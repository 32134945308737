export default function Hero404() {
  return (
    <>
      <section className="h-screen min-w-full bg-cover bg-gradient-to-b from-indigo-900 to-purple-600">
        <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center absolute z-10 ">
          <div className="pl-6 mt-8 flex items-center">
            <a href="/">
              <img src="/img/logo-2-branco.png" alt="Logo Tecnvs" />
            </a>
          </div>
          <div className="flex flex-col w-full text-center pt-40 text-white z-10">
            <h1 className=" text-7xl font-bold leading-tight text">Oops!</h1>
            <p className="py-4 text-center text-5xl lg:px-10">
              Página não encontrada. <br />
            </p>
            <div className={`items-center`}>
              <a href="/">
                <button
                  className={`bg-white mt-8 w-max rounded-full 
              hover:bg-transparent text-purple-700
              font-bold hover:text-white
              py-2 px-4 border border-white
              hover:border-white`}
                >
                  Voltar para a Home
                </button>
              </a>
            </div>
          </div>
        </div>
        <div
          className="h-full min-w-full opacity-50 z-0"
          style={{
            backgroundImage: "url(./img/hero-bg-8.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </section>
    </>
  );
}
